<template>
  <div>
    <Main>
      <a-row :gutter="25">
        <a-col :sm="24" :xs="24">
          <NoticeWrapper>
            <sdCards headless>
              <router-link to="/" class="strikingDash-logo">
                <img style="{ width: '120px' }" :src="require('/public/gce_logo.png')" alt="" />
              </router-link>
              <div class="coming-soon-content">
                <h1 v-if="!editProfileMode">{{$t('views.registration.thankYou')}}</h1>
                <p>
                  {{$t('views.registration.verification')}}
                </p>
                <p v-if="userType === 'ClientUser'">
                  {{$t('views.registration.allowedShipments', {number: allowedShipmentRequests?.value})}}
                  <br />
                </p>
                <p v-if="['AgentUser', 'ClientUser'].includes(userType)">
                  <a @click="clearUser()">
                    {{
                      editProfileMode ?
                      $t('views.registration.homePage')
                      : $t('views.registration.loginHere')
                    }}
                  </a>
                </p>
              </div>
            </sdCards>
          </NoticeWrapper>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import {Main} from '@/components/shared/styledComponents/Main';
import {NoticeWrapper} from '@/components/shared/styledComponents/Notice';
import {computed} from '@vue/runtime-core';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {useRouter} from 'vue-router';

export default {
  name: 'RegistrationSuccess',
  components: {
    Main,
    NoticeWrapper
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const {
      dispatch, getters, state
    } = useStore();
    const userType = computed(() => state.session.userType);
    const editProfileMode = computed(() => route.query.edit && JSON.parse(route.query.edit));
    const allowedShipmentRequests = computed(() => getters.allowedShipmentRequestsForNonVerifiedClient);
    const currentUser = computed(() => state.session.currentUser);
    const clearUser = () => {
      if (currentUser.value && !editProfileMode.value) {
        dispatch('logout');
      } else {
        router.push('/');
      }
    };

    return {
      userType,
      allowedShipmentRequests,
      route,
      editProfileMode,
      clearUser
    };
  }
};
</script>
